@import "./common.scss";
@import "./../../../node_modules/@lmig/lmds-react/base.css";

/* VARIABLES */
$white:#fff;
$paleGrey: #dee2e6;
$lightGrey: #565656;
$darkGrey: #444;
$lightBlue: #2A9FD8; 
$libertyBlue: #1A1446;
$libertyTeal: #037B86;

/* Contact Icon */
.lm-IconContact {
  border: 0.7em solid white;
  border-radius: 50%;
  background-color: white; 
}

.lm-Icon-label {
  font-weight: 500; 
  color: $libertyBlue;
}

.lm-IconWrapper {
  position: absolute;
  top: 2em;
  right: 0em;
  
  @media only screen and (min-width : 350px) {
    margin-top: -1.4em !important;
  }

  @media only screen and (min-width : 415px) {
    margin-top: inherit !important;
  }
}

.show {
  display:block;
  position: relative;
}

.hide {
  display:none;
  width: 100%;
  position: relative;
}

.inner {  
  width: 100%;
  margin-top: 5em;
  margin-bottom: 1em;
  text-align:center;
}

.col-center {
  @media only screen and (min-width : 992px) {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}

.defaultColor {
  background-color: $white;
}

.removedColor {
  background-color: #f5f5f5;
}

.errorText {
  color: #D32F2F;
}


/* BREADCRUMB */
@mixin linkText {
  span {
    padding-right: 1em;
    vertical-align: middle;
  }

  img {
    width: 0.7em;
    height: 0.7em;  
    margin-right: 1em; 
  }
}

.breadcrumb {
  line-height: 2em;
  margin: 1.4em 0 0 10%;
  vertical-align: middle;
  font-family: Roboto;
  font-size: 1em;
  background-color: white;
  margin-left: -0em !important;

  .linkTextDark {
    @include linkText;

    span {
      color: $darkGrey;
      font-weight: bold;
    }
  }

  .linkTextLight {
    @include linkText;

    span {
      color: $lightGrey;
      font-weight: normal;
    }
  }
} 
/* BREADCRUMB */
@mixin linkText {
  span {
    padding-right: 8px;
    vertical-align: middle;
  }

  img {
    width: 10px;
    height: 10px;  
    margin-right: 8px; 
  }
}

.breadcrumb {
  line-height: 2em;
  margin:16px 0 0 10% /*112px*/;
  vertical-align: middle;
  font-family: Roboto;
  font-size: 13px;

  .linkTextDark {
    @include linkText;

    span {
      color: $darkGrey;
    }
  }

  .linkTextLight {
    @include linkText;

    span {
      color: $lightGrey;
    }
  }
} 

.lm-FieldGroup-field--checkbox .lm-FieldOption-input[type='checkbox']+.lm-FieldOption-tick+.lm-FieldOption-text {
  height: 1.5em;
  
} 

/* Contact Icon */
.lm-IconContact {
  border: 10px solid #ffffff;
  border-radius: 50%;
  background-color: white; 
}

.lm-Icon-label {
  font-weight: 500; 
  color: $libertyBlue;
}

.lm-IconWrapper {
  position: absolute;
  top: 32px;
  right: 0px; 
}

.lm-FieldOption-text {
  line-height: 1.5em !important;
  margin-top: .5em !important;
}

/* Help Icon */
.icon-info {
  /* https://codepen.io/sosuke/pen/Pjoqqp*/
  filter: invert(28%) sepia(85%) saturate(1286%) hue-rotate(155deg) brightness(95%) contrast(98%);
}

.icon-info-mobile {
  position: absolute;
  margin-left: 1.5em !important;
  margin-top: 3em;
}

.sticky-top {
  z-index: 0;
  float: right;
}

.icon-info-font {
  text-align: center;
  font-size: 70%;
  line-height: 1.5em;
  margin-right: -4em !important;
  margin-left: 2em;
  margin-top: 4.5em;
  color: $libertyTeal;
}

.icon-info-mobilewithText {
  position: absolute;
  margin-left: 1.75em !important;
  margin-top: -1.25em;
}

/* MAIN */
.main {
  display:block;
  padding:0.02em 0.15em;
  border-top: none;
  border-bottom: none;
  background: $white;
  word-break:break-word;
  word-wrap:break-word;
  margin: 1em 0em 2em 0em;

  &:before {
    content: '';
    display:block;
    position:absolute;
    z-index:2;
    left:0;
    width:100%;
    height:0.75em;
    opacity:0.5;
    background-image: linear-gradient($lightGrey, $white);
  }

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    background: $lightGrey;
    left: 0;
    margin-top: 2em;
  }
}

/* FOOTER LINKS */
.footer-nav2 {
  ul.legal {
    padding: 0;
    font-size: 0.86em; 

    li {
      
      .list-group-item {
        position: relative;
        display: block;
        border: 0em;
        border-radius: 0em !important;
        padding: 0px 8px 0px 0px;
        cursor: pointer;
        line-height: 1em;

        a {
          cursor: pointer;
          text-decoration: none !important;
        }

        @media only screen and (min-width : 375px) {
          border-right: 0em !important;
        }

        @media only screen and (min-width : 415px) {
          border-right: 0.05em solid !important;
        }

        text-decoration: none !important;
      }
      
      list-style: none;
      color: #06748C;
      display: inline-block;
      padding: 0 0.5em 0 0;
    }
  }

  ul.list-group {
    display: inherit;

    li:last-child a {
      border-right: 0em !important;
    }
  }

  .partners {
    overflow:auto;
    float: right;
  }
}

/* FOOTER SECURITY PARTNERS*/
.partners-security {
  img {
    width: 90%;
    height: 90%;
  }

  a {
    vertical-align: middle;
  }

  div {
    display: inline-block;
    vertical-align: middle;
    width: 6.8em;
  }
}

/* LOADER */
.loader-overlay {
  position:fixed;
  top:0;
  left:0;
  z-index:9999;
  width:100%;
  height:100%;
  background:rgba(0,0,0,0.1);
}

.loader-overlay .loader-container {
  position:relative;
  top:75px;
  max-width:240px;
  margin:10em auto;
  padding:15px;
  border:1px solid #b0b1b2;
  background:#fff;
}

.loader {
}

.loader .loader-animation {
  position:relative;
  display:inline-block;
  height:30px;
  width:30px;
  margin:0 10px 0 0;
  padding:6px;
  -webkit-animation:rotate 1s linear infinite;
  animation:rotate 1s linear infinite;
  vertical-align:middle;
  background:#ecac00;
  border-radius:50%;
}

@-webkit-keyframes rotate {
  from {
    -webkit-transform:rotate(0deg);
  }
  to {
    -webkit-transform:rotate(360deg);
  }
}

@keyframes rotate {
  from {
    transform:rotate(0deg);
  }
  to {
    transform:rotate(360deg);
  }
}

.loader .loader-animation:before,
.loader .loader-animation:after {
  content:'';
  position:absolute;
}

.loader .loader-animation:before {
  border-radius:50%;
  background:linear-gradient(0deg, hsla(0, 0%, 100%, 1) 50%, hsla(0, 0%, 100%, 0.9) 100%) 0% 0%, linear-gradient(90deg, hsla(0, 0%, 100%, 0.9) 0%, hsla(0, 0%, 100%, 0.6) 100%) 100% 0%, linear-gradient(180deg, hsla(0, 0%, 100%, 0.6) 0%, hsla(0, 0%, 100%, 0.3) 100%) 100% 100%, linear-gradient(360deg, hsla(0, 0%, 100%, 0.3) 0%, hsla(0, 0%, 100%, 0) 100%) 0% 100%;
  background-repeat:no-repeat;
  background-size:50% 50%;
  top:-1px;
  bottom:-1px;
  left:-1px;
  right:-1px;
}

.loader .loader-animation:after {
  top:5px;
  bottom:5px;
  left:5px;
  right:5px;
  background:#fff;
  border-radius:50%;
}

.loader .loader-content {
  display:inline-block;
  vertical-align:middle;
}

.loader .loader-content h5 {
  margin:0;
}
