/* VARIABLES */
$white:#fff;
$paleGrey: #dee2e6;
$lightGrey: #9ca4ab;
$darkGrey: #444;
$lightBlue: #2A9FD8; 
$libertyTeal: #037B86;

/* ALERT MESSAGE */
.alert-message {
  display:table;
  table-layout:fixed;
  width:100%;
  margin:20px 0;

  .alert-icon{ 
    width:50px;
    padding-right: 14px;
  }
}

.helplink {
  color: $libertyTeal !important;
}

.alert-message .alert-icon,
.alert-message .alert-content {
  display:table-cell;
}

// .alert-message .alert-icon {
//   width:50px;
//   padding-right: 14px;
// }

.state-no-margin {
  margin:0 !important;
}

.alert-message.state-error.state-no-margin .alert-icon .alert-icon-color {
  filter: brightness(0) saturate(100%) invert(21%) sepia(51%) saturate(4145%) hue-rotate(343deg) brightness(92%) contrast(94%);
}

.alert-message.state-error .highlight{
  color:#c9282d;
  font-size: 0.75em;
}

/* CONTAINER */
.container {
    position: relative;
    margin: 0 auto;
    padding: 0; 
    box-sizing: border-box;
    max-width: 100%;

  /* SECTION CONTAINER */
  .sectionContainer {
    margin:0em;
    background: $white;

    /* SECTION CONTENT */
    .sectionContent {
      margin: 0em;
      max-width: 75em;

      .headroom {
        margin-top: 1em;
      }

      .continueBtnPremInfoSummary {
        max-width: 100%;
      }

      .noPadRight {
        padding-right:0
      }

      .centerContent {
        text-align: center !important;
      }

      article {
        .headroom {
            margin-top: 1em;
        }

        .smallHeadroom {
          margin-top: 2em;
        }
      }

      /* NAVIGATION */
      .backwardNavigation {
        line-height: 2em;
        margin-top:2em;
        vertical-align: middle;

        .navigationLink {
          margin-right: 1em;
          vertical-align: middle;
          filter: invert(55%) sepia(42%) saturate(782%) hue-rotate(155deg) brightness(92%) contrast(95%); /* https://codepen.io/sosuke/pen/Pjoqqp*/          
          
          img {
            width: .5em !important;
            max-width: .5em !important;
          }
        }

        .linkText {
          color: $libertyTeal;
        }
      } /* END NAVIGATION */

      /* CARD */
      .card {
        background-color: $white;
        border-radius: 4px;
        max-width: 25em;
        margin: 10% auto;
        height: auto;
        position: relative;
        padding: 2em;
        color: $darkGrey;
        box-shadow: 0 0.5rem 1rem $lightGrey !important;
        border: 1px solid $paleGrey !important;
        position: -webkit-sticky;
        position: sticky;
        top:2em;
      } /* END CARD */

      .alertCard {
        max-width: 100%;
        margin: 1em;
        padding: 0.5em;
      }

      .quoteCard {
        margin: 1em auto !important;
      }

      .confirmation {
        max-width: 35em;
        display: flex;
        flex-direction: column;
        min-width: 0px;
        overflow-wrap: break-word;
        height: auto;
        position: sticky;
        top:2em;
      }

      .quoteTitle {
        margin-top: 0em;
        margin-bottom: 0em;
        font-weight: 100;
      }

      .quoteSubTitle {
        margin-top: 0em;
        margin-bottom: 1em;
        font-weight: 100;
      }

      .confirmationSubTitle {
        margin-top: 0em;
        margin-bottom: 1em;
        font-weight: bold;
      }

      .quotePremium {
        margin-top: 0em;
        font-weight: 600;
      }

      .line-width {
        width: 100% !important;
      }

      .card-col1 {
        max-width: 5%;
      }

      .card-col2 {
        margin-left: -1em;
      }

      .icon-info {
        margin-left: -1em;
        margin-right: .5em;
      }

      .error-adj-1 {
        margin-top: -3.75em !important;
      }

      .error-adj-2 {
        margin-top: -4.25em !important;
      }

      .quoteRow {
        margin-left: -25px !important;
      }

      .summaryRow {
        display: flex;
        flex-direction: row;
        background-color: $paleGrey;
        margin-left: 0px;
        margin-right: 0px;
        margin-top: .5em;
        height: auto;
        padding-top: 0.5em;
        padding-bottom: 0.5em;
        align-items: center;
      }

      /* TERM */
      .term {        
        border-radius: 4px;
        max-width: 40em;
        height: auto;
        position: relative;
        padding: 0em 1em 1em 1em;
        margin-bottom: 1em;
        /* padding-top: -4em; */
        border: 1px solid $darkGrey !important;

        a.removeLink {          
          font-size: 85%;
          position: relative;
          z-index: 99;
          text-decoration: underline;
        }      
        
        .lm-FieldGroup {
          margin-top: 1.5em;
        }

        .studyAbroad {
          margin: .5em;
          .lm-FieldGroup {
            margin-top: .75em;
            .lm-FieldGroup-label .lm-Heading4 {
              font-size: 1.1em;
            }
          }
        }
        .visible {
          display:block !important;
        }
        
        .invisible {
          display:none !important;
        }

        .row {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          width: 100%;
          margin-left: 0px !important;
          margin-right: 0px !important;
        }
        
        .column {
          display: flex;
          flex-direction: column;
          flex-basis: 100% !important;
          flex: 1;
          padding-left: 3px !important;
          padding-right: 3px !important;
        }

        .floatRight {
          float: right;
          margin: -3.5em 1em 0em 0em; 
        }

      } /* END TERM */

    @media only screen and (min-width : 360px) {
      .quoteCoverages .row .summaryRow .col {
        font-size: 77% !important;
      }

      .quoteTerms .row .summaryRow .col {
        font-size: 77% !important;
      }

      .continueBtnPremInfoSummary {
        font-size:78% !important;
      }

      .quoteSummary {
        margin-left: 0px !important;

        .totalPremium {
          font-size: .85rem;
          font-weight: 400;
          padding-left: .75em;
        }
      }

      #confirmationTitle {
          font-size: 1.7rem !important;
          font-weight: 300 !important;
      }
    }
  
    @media only screen and (min-width : 415px) {
      .quoteCoverages .row .summaryRow .col {
        font-size: inherit !important;
      }

      .quoteTerms .row .summaryRow .col {
        font-size: inherit !important;
      }

      .continueBtnPremInfoSummary {
        font-size: inherit !important;
      }

      .quoteSummary {
        padding-left: 0px;

        .totalPremium {
          font-size: .85rem;
          font-weight: 400;
          padding-left: .75em;
        }
      }

      #confirmationTitle {
          font-size: 1.7rem !important;
          font-weight: 300 !important;
      }
    }

    @media only screen and (min-width : 1025px) {
      /* Anything larger than an iPad Pro*/
      .quoteSummary {
        margin-left: -25px !important;

        .totalPremium {
          font-size: .85rem;
          font-weight: 400;
          padding-left: .75em;
        }
      } 
                    
      #confirmationTitle {
        font-size: 2.25rem !important;
        font-weight: 100 !important;
      }
      
      .iframe-non-us {
        display: block;
        width: 100% !important;
        max-width: 600px !important;
        height: 805px !important;
        border: 0;
      }

      .iframe {
        display: block;
        width: 100% !important;
        max-width: 600px !important;
        height: 990px !important;
        border: 0;
      }
    }

    .iframe-non-us {
      display: block;
      width: 100% !important;
      max-width: 600px !important;
      height: 980px !important;
      border: 0;
    }

    .iframe {
      display: block;
      width: 100% !important;
      max-width: 600px !important;
      height: 1180px !important;
      border: 0;
    }

    .alert {
      display:block;
      padding:20px;
      padding: 0em 1.25rem 0em 1.25em !important;
    }
    
    .alert-error {
      color:#a8241d;
      font-size:0.77em;
      font-weight:700;
      font-family: Helvetica;
    }

    } /* END SECTION CONTENT */
    

  } /* END SECTION CONTAINER */

  .footroom {
    margin-bottom: 2em;
  }
  
  .margin-override {
    margin-right: 3em !important;
  }

  .checkbox-override {
    margin-top: .95em;
    line-height: 1.25em;
  }

  .totalPremium {
    font-size: .85rem;
    font-weight: 400;
    padding-left: .75em;
  }

  .overlay {
    margin-top: 2em;
  }

  .payment-margin-override {
    margin-top: 0 !important;
  }
} /* END CONTAINER */
